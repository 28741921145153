<template>
  <!-- header of the page -->
  <header id="header">
    <div class="container">
      <!-- brand logo -->
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo.svg" alt="" />
        </router-link>
      </div>
      <!--      <div id="nav-opener">-->
      <!--        <span></span>-->
      <!--        <span></span>-->
      <!--        <span></span>-->
      <!--      </div>-->
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderNoWallet",
};
</script>

<style scoped></style>
